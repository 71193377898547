import { useTranslation } from 'react-i18next';

import React, { useState } from 'react';
import { Flex, Link } from 'theme-ui';
import { navigate, RouteComponentProps } from '@reach/router';

import { useUser } from '@youga/youga-client-api';

import useAuth from '../../../hooks/useAuth';
import useSubscriptionStatus from '../../../hooks/useSubscriptionStatus';

import Header from '../../02_molecules/Header/Header';
import Text from '../../01_atoms/Text/Text';
import Button from '../../01_atoms/Button/Button';
import Container from '../../01_atoms/Container/Container';
import Spinner from '../../01_atoms/Spinner/Spinner';
import styled from 'styled-components';
import TextInput from '../../01_atoms/TextInput/TextInput';
import CodeInfoModal, { IInformationScreen } from './CodeInformationModal';
import API from '../../../services/API';
import { Auth } from 'aws-amplify';

const Space = styled.div`
  height: 10rem;
`;

const MyAccountSection: React.FC<RouteComponentProps> = () => {
  const { t } = useTranslation();

  const { handleSignOut, token } = useAuth();

  const { data: user, isValidating } = useUser();
  const [voucherInputOpen, setVoucherInputOpen] = useState(false);
  const [voucherCode, setVoucherCode] = useState('');
  const [informationScreen, setInformationScreen] =
    useState<IInformationScreen | null>(null);

  const sendVoucher = async () => {
    try {
      console.log('send voucher', voucherCode);

      if (token) {
        console.log('send voucher', voucherCode);
        await API.applyVoucher(token, voucherCode);
      }
      setInformationScreen({
        allowClose: true,
        title: t('voucher_activated'),
        description: t('voucher_activated_desc'),
        buttonLabel: t('voucher_activated_label'),
        iconColor: 'success',
        onLabelClick: () => navigate('/app/'),
      });
    } catch (error) {
      setInformationScreen({
        allowClose: true,
        title: t('voucher_code_invalid'),
        description: t('voucher_code_invalid_desc'),
        buttonLabel: t('voucher_code_invalid_label'),
        iconColor: 'error',
      });
    }
  };

  const headingStyles = {
    color: 'grey3',
    display: 'block',
    fontWeight: '600',
    fontSize: '12px',
    marginTop: 5,
  };

  const formatDate = (isoDate?: string | number): string => {
    if (!isoDate) return '';
    const zeroFill = (input: number): string => {
      if (input < 10) return `0${input}`;
      return `${input}`;
    };
    const date = new Date(isoDate);
    return `${zeroFill(date.getDate())}.${zeroFill(
      date.getMonth() + 1,
    )}.${date.getFullYear()}`;
  };

  if (user == null && isValidating) {
    return (
      <Container>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </Container>
    );
  }

  return (
    <>
      <CodeInfoModal
        informationScreen={informationScreen}
        setInformationScreen={setInformationScreen}
      />
      <div id="overlay-modal" />
      <Header showButton="back" theme="light" />
      <Container>
        <Text
          variant="h2"
          sx={{
            fontSize: [2, 4],
            mt: [3, 7],
            mb: [7, 12],
            display: 'block',
          }}
          highlighted
        >
          {t('MEIN_ACCOUNT')}
        </Text>
        <Text variant="heading" sx={headingStyles}>
          {t('DEINE_EMAIL_ADRESSE')}
        </Text>
        <Text>{user?.email}</Text>
        <Text variant="heading" sx={headingStyles}>
          {t('ANGEMELDET_SEIT')}
        </Text>
        <Text>{formatDate(user?.stats?.joinDate)}</Text>
        <Text variant="heading" sx={headingStyles}>
          {t('DEIN_AKTUELLES_ABONNEMENT')}
        </Text>
        <Text>{`${t('GEKAUFT_AM')} ${formatDate(
          '2023-02-06T15:40:01Z',
        )})`}</Text>

        <Flex
          style={{
            flexDirection: 'column',
            maxWidth: 'max-content',
            alignItems: 'center',
          }}
        >
          <Button
            variant="primary"
            sx={{
              marginTop: 5,
            }}
            onClick={(): void => {
              handleSignOut();
            }}
          >
            {t('AUSLOGGEN')}
          </Button>
          <Button
            variant="primary"
            sx={{
              marginTop: 5,
            }}
            onClick={(): void => {
              navigate('/app/my-account/cookie-manager');
            }}
          >
            {t('COOKIE_MANAGER')}
          </Button>

          <Button
            variant={'primary'}
            sx={{
              marginTop: 5,
            }}
            onClick={(): void => {
              setVoucherInputOpen(!voucherInputOpen);
            }}
          >
            {t('have_voucher')}
          </Button>

          {voucherInputOpen && (
            <Flex
              sx={{
                marginTop: 4,
                flexDirection: 'column',
              }}
            >
              <TextInput
                id="code"
                style={{ width: '320px' }}
                name="code"
                onChange={(e) => setVoucherCode(e.target.value)}
                placeholder={t('voucher_placeholder')}
              />
              <Button
                variant="whiteBorder"
                sx={{
                  marginTop: 0,
                }}
                disabled={voucherCode.length === 0}
                onClick={sendVoucher}
              >
                {t('send_voucher')}
              </Button>
            </Flex>
          )}

          <Link
            style={{
              cursor: 'pointer',
              marginTop: 24,
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 'normal',
            }}
            onClick={async (e) => {
              if (token) {
                console.log('send voucher', voucherCode);
                await API.selfDelete(token);
                await Auth.signOut();
              }
              window.location.href = t('MAILTO_INFO_YUUXYOGA_DE_SUBJEC');
              e.preventDefault();
            }}
          >
            {t('MEINE_ACCOUNT_LOSEN')}
          </Link>
        </Flex>

        <Space />
      </Container>
    </>
  );
};

export default MyAccountSection;
