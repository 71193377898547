import React from 'react';
import { LabelName } from '@youga/youga-interfaces';

const labelMapper = {
  NEW: { text: 'Neu', color: '#ffffff' },
  POPULAR: { text: 'Besonders Beliebt', color: '#cfebe9' },
};

interface LabelProps {
  labels: LabelName[];
}

const Labels: React.FC<LabelProps> = ({ labels }: LabelProps) => (
  <div
    style={{
      display: 'flex',
      justifyContent: 'flex-start',
      flexDirection: 'row',
      flexWrap: 'wrap',
      zIndex: 1,
      position: 'absolute',
      top: 8,
      left: 16,
    }}
  >
    {labels.length > 0 &&
      labels.map((label, i) => (
        <div
          key={i}
          style={{
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 0,
            paddingBottom: 2,
            borderRadius: 12,
            marginRight: 8,
            marginBottom: 8,
            backgroundColor: label && labelMapper[label].color,
          }}
        >
          <span
            style={{
              fontFamily: 'Montserrat,sans-serif',
              fontWeight: '600',
              fontSize: 9,
              letterSpacing: 0.75,
              textTransform: 'uppercase',
              lineHeight: 0,
            }}
          >
            {label && labelMapper[label].text}
          </span>
        </div>
      ))}
  </div>
);

export default Labels;
