export default {
  variants: {
    primary: {
      cursor: 'pointer',
      fontSize: 1,
      lineHeight: 1.4,
      fontWeight: '600',
      fontFamily: 'heading',
      color: 'black',
      bg: 'primary',
      borderRadius: 30,
      borderWidth: 0,
      textAlign: 'center',
      px: 4,
      py: '0.75rem',
      transition: 'background-color 0.3s ease-in, opacity 0.3s ease-in',
      width: ['100%', '340px'],
      maxWidth: '100%',
      ':disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
      ':focus': {
        outline: 'none',
      },
      '@media (max-width: 374px)': {
        fontSize: 0,
      },
    },
    smallPrimaryToggle: {
      cursor: 'pointer',
      fontSize: ['10px', '12px'],
      lineHeight: 1.4,
      fontWeight: '600',
      fontFamily: 'heading',
      color: 'black',
      backgroundColor: 'white',
      borderRadius: '24px',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: 'black',
      textAlign: 'center',
      px: [3, 4],
      py: 1,
      transition: 'background-color 0.3s ease-in, opacity 0.3s ease-in',
      width: ['auto'],
      maxWidth: '100%',
      ':disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
      ':focus': {
        outline: 'none',
      },
      '@media (max-width: 374px)': {
        fontSize: 0,
      },
    },
    smallPrimaryToggleActive: {
      variant: 'buttons.smallPrimaryToggle',
      backgroundColor: 'primary',
      borderColor: 'primary',
      color: 'white',
    },
    smallPrimaryToggleSubtle: {
      variant: 'buttons.smallPrimaryToggle',
      backgroundColor: 'white',
      borderColor: 'grey4',
      color: 'grey4',
    },
    white: {
      fontSize: 1,
      lineHeight: 1.4,
      fontWeight: '600',
      fontFamily: 'heading',
      color: 'black',
      bg: 'white',
      borderRadius: 30,
      borderWidth: 0,
      textAlign: 'center',
      px: 4,
      py: '0.75rem',
      width: ['100%', '340px'],
      maxWidth: '100%',
      cursor: 'pointer',
      ':disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
      ':focus': {
        outline: 'none',
      },
      '@media (max-width: 374px)': {
        fontSize: 0,
      },
    },
    whiteBorder: {
      fontSize: 1,
      lineHeight: 1.4,
      fontWeight: '600',
      fontFamily: 'heading',
      color: 'black',
      bg: 'white',
      borderRadius: 30,
      border: '1px solid black',
      textDecoration: 'none',
      textAlign: 'center',
      px: 4,
      py: '0.75rem',
      width: ['100%', '340px'],
      maxWidth: '100%',
      cursor: 'pointer',
      ':disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
      ':focus': {
        outline: 'none',
      },
      '@media (max-width: 374px)': {
        fontSize: 0,
      },
    },
    'dark-solid': {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'black',
      width: 'calc(100% - 2rem)',
    },
    'dark-transparent': {
      variant: 'buttons.primary',
      color: 'primary',
      bg: 'black',
      width: 'calc(100% - 2rem)',
    },
    ghost: {
      variant: 'buttons.primary',
      bg: 'transparent',
      borderColor: 'white',
    },
    primaryGhost: {
      cursor: 'pointer',
      fontSize: 1,
      lineHeight: 1,
      fontWeight: '600',
      fontFamily: 'heading',
      color: 'primary',
      bg: 'transparent',
      borderRadius: 30,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'primary',
      textAlign: 'center',
      px: 8,
      py: 3,
      width: ['100%', '340px'],
      maxWidth: '100%',
      ':disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
      ':focus': {
        outline: 'none',
      },
      '@media (max-width: 374px)': {
        fontSize: 0,
      },
    },
    ghostWhite: {
      fontSize: 1,
      lineHeight: 1.4,
      fontWeight: '600',
      fontFamily: 'heading',
      color: 'white',
      bg: 'transparent',
      borderRadius: 30,
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'white',
      textAlign: 'center',
      px: 4,
      py: '0.75rem',
      width: ['100%', '340px'],
      maxWidth: '100%',
      cursor: 'pointer',
      ':disabled': {
        opacity: 0.3,
        cursor: 'not-allowed',
      },
      ':focus': {
        outline: 'none',
      },
      '@media (max-width: 374px)': {
        fontSize: 0,
      },
    },
    text: {
      bg: 'transparent',
      border: 0,
      fontSize: 0,
      fontFamily: 'heading',
      color: 'grey3',
      textDecoration: 'underline',
      padding: 0,
      cursor: 'pointer',
      width: ['100%', '340px'],
      maxWidth: '100%',
    },
    textGrey: {
      bg: 'transparent',
      border: 0,
      fontSize: 0,
      fontFamily: 'body',
      color: 'grey3',
      textDecoration: 'underline',
      padding: 0,
      cursor: 'pointer',
    },
    inputbuttongroup: {
      borderRadius: 0,
      py: 3,
      px: 5,
      fontWeight: '600',
      borderTopRightRadius: 'small',
      borderBottomRightRadius: 'small',
      ':hover': {
        bg: 'grey_4',
        cursor: 'pointer',
      },
      ':disabled': {
        bg: 'grey1',
        cursor: 'not-allowed',
      },
    },
  },
};
// inactive: {
//   variant: 'buttons.primary',
//   color: 'black',
//   bg: 'transparent',
//   border: '1px solid black',
//   ':hover': {
//     bg: 'transparent',
//     cursor: 'pointer',
//   },
// },
// secondary: {
//   variant: 'buttons.primary',
//   color: 'black',
//   bg: 'gray',
// },
