import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ThemeUIStyleObject, Box } from 'theme-ui';
import Text from '../Text/Text';

interface ViewMoreText {
  separator: string;
  children: string;
  className?: string;
  sx?: ThemeUIStyleObject;
  paragraphStyle?: ThemeUIStyleObject;
}

const ViewMoreText: React.FC<ViewMoreText> = ({
  separator,
  children,
  className,
  paragraphStyle,
  sx,
}) => {
  const [showMore, setShowMore] = useState(false);
  const { t } = useTranslation();
  const toggle = useCallback((): void => {
    setShowMore((state) => !state);
  }, []);

  if (typeof children !== 'string') {
    throw new Error('ViewMoreText only allows strings as children');
  }

  const text = children as string;

  const paragraphs = text.split('\n\n');

  // If the separator is not included, just hand over to the regular Text component
  if (!text.includes(separator)) {
    return (
      <Box>
        {paragraphs.map((p, index) => (
          <Box key={index} sx={paragraphStyle}>
            <Text className={className} sx={sx}>
              {p}
            </Text>
          </Box>
        ))}
      </Box>
    );
  }

  const viewMoreParagraph = paragraphs.findIndex((p) => p.includes(separator));

  return (
    <Box onClick={toggle} sx={{ cursor: 'pointer' }}>
      {paragraphs.map((p, index) => {
        let baseText = p;
        if (index === viewMoreParagraph) {
          baseText = p.substr(0, p.indexOf(separator));
        } else if (index > viewMoreParagraph) {
          baseText = '';
        }

        let moreText = '';
        if (index === viewMoreParagraph) {
          moreText = p.substr(p.indexOf(separator) + separator.length);
        } else if (index > viewMoreParagraph) {
          moreText = p;
        }

        return (
          <Box key={index} sx={paragraphStyle}>
            <Text className={className} sx={sx}>
              {baseText}

              {index === viewMoreParagraph && !showMore && (
                <span style={{ fontWeight: '600', whiteSpace: 'nowrap' }}>
                  {' '}
                  {t('READ_MORE')}
                </span>
              )}

              {showMore && moreText}

              {index === viewMoreParagraph && showMore && (
                <span style={{ fontWeight: '600', whiteSpace: 'nowrap' }}>
                  {' '}
                  {t('READ_LESS')}
                </span>
              )}
            </Text>
          </Box>
        );
      })}
    </Box>
  );
};

export default ViewMoreText;
