/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { Box, Grid } from 'theme-ui';
import { Link, navigate } from 'gatsby';
import { useBreakpointIndex } from '@theme-ui/match-media';
import { RouteComponentProps } from '@reach/router';

import {
  useCourses,
  useInstructors,
  useUser,
  useVideoSessions,
  useFavorites,
} from '@youga/youga-client-api';

import VideoItem from '../../01_atoms/VideoItem/VideoItem';
import Text from '../../01_atoms/Text/Text';
import Spinner from '../../01_atoms/Spinner/Spinner';
import Header from '../../02_molecules/Header/Header';
import Container from '../../01_atoms/Container/Container';
import useTracking from '../../../hooks/useTracking';
import LevelProps from '../../02_molecules/SessionsLevelProps/SessionsLevelProps';
import VideoOverlay from '../../01_atoms/VideoOverlay/VideoOverlay';
import ViewMoreText from '../../01_atoms/ViewMoreText/ViewMoreText';
import Button from '../../01_atoms/Button/Button';
import { useTranslation } from 'react-i18next';
import i18n from '../../../i18n/i18n';

const getDurationInSeconds = (duration: string): number => {
  const [minutes = '0', seconds = '0'] = duration.split(':');
  return parseInt(minutes, 10) * 60 + parseInt(seconds, 10);
};

function generateUUID() {
  // Public Domain/MIT
  let d = new Date().getTime(); //Timestamp
  let d2 =
    (typeof performance !== 'undefined' &&
      performance.now &&
      performance.now() * 1000) ||
    0; //Time in microseconds since page-load or 0 if unsupported
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    let r = Math.random() * 16; //random number between 0 and 16
    if (d > 0) {
      //Use timestamp until depleted
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      //Use microseconds since page-load if supported
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
  });
}

export type CourseDetailSectionProps = RouteComponentProps<{
  courseId: string;
  startIntro?: string;
}>;

const CourseDetailSection = ({
  courseId,
  startIntro,
}: CourseDetailSectionProps): React.ReactElement => {
  const { data: instructorsData } = useInstructors();
  const { data: coursesData } = useCourses(i18n.language);
  const { data: videoSessionsData } = useVideoSessions(i18n.language);

  const { data: user } = useUser();
  const [videoVisible, setVideoVisible] = useState(false);
  const { track } = useTracking();
  const course = coursesData?.courses[courseId || 'unknown'];
  const breakpointIndex = useBreakpointIndex();

  const [videoPlaying, setVideoPlaying] = React.useState(false);
  const playTimer = React.useRef(0);
  const completionRate = React.useRef(0);
  const trainingSessionId = React.useRef(generateUUID().toString());

  const { updateFavorites } = useFavorites(course?.id);
  const { t } = useTranslation();

  React.useEffect(() => {
    if (!videoPlaying) {
      return;
    }

    let mounted = true;

    async function clockTicked() {
      playTimer.current += 1;

      const totalVideoDuration = course?.introVideoDuration
        ? getDurationInSeconds(course?.introVideoDuration)
        : 0;

      const newCompletionRate = Math.floor(
        100 * (playTimer.current / totalVideoDuration),
      );

      if (
        newCompletionRate % 2 === 0 && // Send updates every 2% completion
        newCompletionRate > completionRate.current &&
        newCompletionRate <= 100
      ) {
        try {
          if (!mounted) {
            return;
          }
          await track('training-session-completion-update', {
            trainingSessionId: trainingSessionId.current,
            videoCode: 'video-code',
            videoSessionId: `${course?.id}-intro`,
            videoSessionName: course?.title,
            completionRate: Math.min(completionRate.current, 100),
            smartMirrorOn: false,
            mobileMirrorrlessOverlay: false,
            instructorId: course?.instructor,
            channels: 'ios-only',
          });
          completionRate.current = newCompletionRate;
        } catch (error: unknown) {
          console.log(
            'Tracking training-session-completion-update failed',
            error,
          );
        }
      }
    }

    const id = setInterval(clockTicked, 1000);
    return () => {
      mounted = false;
      clearInterval(id);
    };
  }, [course, track, videoPlaying]);

  // 1.- Track HomeSection
  useEffect(() => {
    track('course-detail-section', { courseId });
  }, [courseId, track]);

  useEffect(() => {
    if (course && course.preview) {
      navigate('/app');
    }
  }, [course]);

  if (
    // (coursesData == null && isValidatingCourses) ||
    !course ||
    course.preview
  ) {
    return (
      <>
        <Header showButton="back" theme="light" />
        <Spinner fullscreen />
      </>
    );
  }

  return (
    <>
      <Header
        showButton={!videoVisible ? 'back' : null}
        theme={videoVisible ? 'dark-solid' : 'light'}
      />

      {course.introVideoMp4Url && (
        <VideoOverlay
          visible={videoVisible || startIntro == 'start-intro'}
          videoUrl={course.introVideoMp4Url}
          videoMaxWidth={1920}
          closeOnFinish
          onPlay={(): void => {
            const trackResponse = track('video-play', {
              trainingSessionId: trainingSessionId.current,
              videoCode: 'video-code',
              videoSessionId: `${course.id}-intro`,
              videoSessionName: course.title,
              completionRate: completionRate.current,
              smartMirrorOn: false,
              instructorId: course.instructor,
              time: playTimer.current,
            }).catch((e) => console.log('Tracking video-play failed', e));

            console.log(trackResponse);
            setVideoPlaying(true);
          }}
          onFinish={(): void => {
            track('course-detail-intro-video-finished');
          }}
          onClose={(): void => {
            setVideoVisible(false);
          }}
        />
      )}

      <Box
        sx={{
          width: '100%',
          position: 'relative',
          lineHeight: 0,
          a: { textDecoration: 'none' },
        }}
      >
        <Box
          sx={{
            filter: 'blur(8.75px)',
            paddingTop: 550,
            backgroundImage: `url(${course.headerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center center',
          }}
        />

        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 0,
            right: 0,
            width: '100%',
            height: '184px',
            background:
              'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.78) 100%)',
            boxShadow: '0px 10px 16px 0px rgba(0, 0, 0, .2);',
          }}
        />

        <Container
          sx={{
            marginTop: -583,
            transform: 'translateY(5%)',
            pb: [3, null, null, null, 1],
            justifyContent: 'center',
            alignSelf: 'center',
          }}
        >
          <Box
            sx={{
              height: '650px',
              maxHeight: '80%',
              backgroundImage: `url(${course.headerImage})`,
              width: '108%',
              maxWidth: '108%',
              marginLeft: breakpointIndex < 3 ? -4 : -7,
              backgroundSize: 'cover',
              backgroundPosition: 'center center',
              boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, .5);',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'flex-end',
                height: '650px',
                padding: 7,
                paddingBottom: 6,
                paddingLeft: breakpointIndex < 3 ? 4 : 7,
                background:
                  'linear-gradient(-180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.60) 100%)',
              }}
            >
              <Text
                variant="h2"
                sx={{
                  maxWidth: '60%',
                  letterSpacing: '-0.04px',
                  fontWeight: '600',
                  color: 'white',
                  fontSize: [3, null, null, 4],
                  mt: 0,
                  mb: 1,
                }}
              >
                {course.title}:
              </Text>
              <Text
                variant="h2light"
                sx={{
                  maxWidth: '60%',
                  letterSpacing: '-0.04px',
                  color: 'white',
                  fontSize: [3, null, null, 4],
                  mt: 0,
                  mb: 2,
                }}
              >
                {course.subtitle}
              </Text>
              <Link to={`/app/instructor-detail/${course.instructor}`}>
                <Text
                  variant="h4light"
                  sx={{
                    color: 'white',
                    mt: 0,
                    mb: 0,
                    fontFamily: 'body',
                  }}
                >
                  by{' '}
                  {(instructorsData?.instructors[course.instructor] || {}).name}
                </Text>
              </Link>
              {user ? (
                <Button
                  variant="white"
                  onClick={updateFavorites}
                  sx={{
                    position: 'absolute',
                    right: 12,
                    textAlign: 'center',
                    justifyContent: 'center',
                    maxWidth: '300px',
                    height: '40px',
                    fontSize: '0.75rem',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {user?.preferences?.favoriteCourses?.indexOf(course.id) !== -1
                    ? t('REMOVE_FROM_FAVORITES')
                    : t('ADD_TO_FAVORITES')}
                </Button>
              ) : null}
            </Box>
          </Box>
        </Container>
      </Box>

      <Box sx={{ backgroundColor: 'grey1', pt: 7, paddingTop: '70px' }}>
        <Container>
          <Text sx={{ fontSize: 3, mb: 3, fontFamily: 'heading' }}>About</Text>

          <Grid columns={[1, 2]} sx={{ rowGap: 5 }}>
            <ViewMoreText
              separator="[view-more]"
              sx={{ fontSize: 0, lineHeight: '21px' }}
              paragraphStyle={{ marginTop: '8px', marginBottom: '8px' }}
            >
              {course.description}
            </ViewMoreText>
            <LevelProps
              data={[
                {
                  type: 'number',
                  title: t('SESSIONS'),
                  value: course.sessions.length,
                },
                { type: 'level', title: 'Level', value: course.level },
                {
                  type: 'string',
                  title: t('PROPS'),
                  value:
                    i18n.language !== 'pl' ? course.propsEng : course.props,
                },
              ]}
            />
          </Grid>
        </Container>
      </Box>

      {course.introThumbnail && course.introVideoUrl && (
        <Box sx={{ position: 'relative', pt: 12 }}>
          <Box
            sx={{
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              height: '230px',
              backgroundColor: 'grey1',
              zIndex: '-1',
            }}
          />

          <Container>
            <Text sx={{ fontSize: 3, mb: 3, fontFamily: 'heading' }}>
              {t('THATS_WAITS_FOR_YOU')}
            </Text>

            <Grid columns={[1, 2, 3]} sx={{ columnGap: [0, 5, 7] }}>
              <VideoItem
                imageSource={course.introThumbnail}
                duration={course.introVideoDuration || '22:22'}
                title={course.introTitle || 'Intro Title'}
                onPressPlay={(): void => {
                  setVideoVisible(true);
                }}
              />
            </Grid>
          </Container>
        </Box>
      )}

      <Box sx={{ position: 'relative', pt: 12 }}>
        <Container>
          <Text sx={{ fontSize: 3, mb: 3, fontFamily: 'heading' }}>
            {t('SESSIONS')}
          </Text>

          <Grid
            columns={[1, 3]}
            sx={{ columnGap: [0, 5, 7], rowGap: [7, null, 11] }}
          >
            {videoSessionsData &&
              course.sessions.map((sessionId: string) => {
                const videoSession = videoSessionsData.videoSessions[sessionId];
                if (!videoSession) {
                  return <Box>{t('ERROR')}</Box>;
                }

                return (
                  <VideoItem
                    key={sessionId}
                    imageSource={videoSession.thumbnailSmallHq}
                    title={videoSession.title}
                    duration={videoSession.duration}
                    description={videoSession.description}
                    target={`/app/session/${videoSession.id}`}
                  />
                );
              })}
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default CourseDetailSection;
