/* eslint-disable @typescript-eslint/no-unused-vars */
import useSWRImmutable from 'swr/immutable';

import { createApiFetcher, useUser } from '@youga/youga-client-api';

import { useEnv } from '../context/EnvContext';
import useAuth from './useAuth';
import { useEffect } from 'react';

interface ResponseBody {
  request_date: string;
  request_date_ms: number;
  subscriber: Subscriber;
}

interface Subscriber {
  entitlements: Entitlements;
  first_seen: string;
  last_seen: string;
  management_url: null;
  non_subscriptions: unknown;
  original_app_user_id: string;
  original_application_version: string;
  original_purchase_date: string;
  other_purchases: unknown;
  subscriptions: Subscriptions;
}

interface Entitlement {
  expires_date: string;
  grace_period_expires_date: null;
  product_identifier: string;
  purchase_date: string;
}

interface Entitlements {
  fullAppAccess: Entitlement;
}

interface Subscriptions {
  yuuyoga_1_m_standard: Subscription;
  yuuyoga_3_m_standard: Subscription;
  yuuyoga_1_y_standard: Subscription;
}

interface Subscription {
  billing_issues_detected_at: null;
  expires_date: string;
  grace_period_expires_date: null;
  is_sandbox: boolean;
  original_purchase_date: string;
  ownership_type: string;
  period_type: string;
  purchase_date: string;
  store: string;
  unsubscribe_detected_at: string;
}

const isEntitlementActive = (
  gracePeriod: number,
  entitlement: Entitlement | undefined,
): boolean => {
  if (!entitlement) {
    return false;
  }

  const expirationDateTimestamp = new Date(entitlement.expires_date).getTime();

  const now = new Date().getTime();
  const isStillValid = expirationDateTimestamp + gracePeriod > now;
  const isInGracePeriod = isStillValid && now > expirationDateTimestamp;

  console.log(
    `Current validity is ${isStillValid ? 'valid' : 'invalid'}. Grace period: ${
      isInGracePeriod ? 'yes' : 'no'
    }`,
  );

  return isStillValid;
};

function useSubscriptionStatus() {
  const env = useEnv();
  const { user } = useAuth();
  const { data: userr } = useUser();

  const userId = user?.attributes?.sub;

  const { data, isValidating } = useSWRImmutable<ResponseBody>(
    userId ? `/subscribers/${userId}` : null,
    {
      refreshInterval: 1000 * 60 * 60 + 1000, // refresh each hour
      fetcher: createApiFetcher(
        'https://api.revenuecat.com/v1',
        env.revenueCatApiKey,
        {
          'Cache-Control': `max-age=${60 * 60}`, // cache in browser for 1 hour
        },
      ),
    },
  );

  let hasFullAppAccess = false;
  let fullAppAccess = false;
  const hasB2BPlan = userr?.plan?.b2b?.length && userr?.plan?.b2b?.length > 4;
  const hasSubscription = isEntitlementActive(
    env.gracePeriod,
    data?.subscriber?.entitlements?.fullAppAccess,
  );
  if (hasSubscription || hasB2BPlan) {
    hasFullAppAccess = true;
    fullAppAccess = true;
  }

  return { data, isValidating, fullAppAccess, hasFullAppAccess };
}

export default useSubscriptionStatus;
