import React, { SyntheticEvent, useRef } from 'react';

import { useTranslation } from 'react-i18next';

import { AuthVariant } from './AuthBox';
import Button from '../../01_atoms/Button/Button';
import Checkbox from '../../01_atoms/Checkbox/Checkbox';

import PasswordInput from '../../01_atoms/PasswordInput/PasswordInput';
import Text from '../../01_atoms/Text/Text';
import TextInput from '../../01_atoms/TextInput/TextInput';
import i18n from '../../../i18n/i18n';

import myBenefitLogo from '../../../assets/logo-mybenefit.png';
import clubHealthLogo from '../../../assets/CHL.png';
import upBonusLogo from '../../../assets/up.png';
import workSmileLogo from '../../../assets/worksmile.png';
// import motivizerLogo from '../../../../assets/motivizer-logo.png';
import { Box } from 'theme-ui';

type SignUpFormProps = {
  errorInformation: null | {
    position: string;
    element: React.ReactNode;
  };
  onClickFacebookLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickGoogleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  onClickAppleLogin: (e: SyntheticEvent<HTMLButtonElement>) => void;
  setEmail: (v: string) => void;
  setPassword: (v: string) => void;
  setCurrentVariant: (variant: AuthVariant) => void;
  conditionsAccepted: boolean;
  setConditionsAccepted: (v: boolean) => void;
  newsletter: string;
  clientCheck: (client: string) => Promise<boolean>;
  setNewsletter: (v: string) => void;
  setCode: (v: string) => void;
  confirmCode?: string;
};

function debounce<T extends any[]>(fn: (...args: T) => void, delay: number) {
  let timeout: NodeJS.Timeout;
  return (...args: T) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => fn(...args), delay);
  };
}

export default function SignUpForm({
  errorInformation,
  clientCheck,
  setEmail,
  setPassword,
  setCurrentVariant,
  conditionsAccepted,
  setConditionsAccepted,
  newsletter,
  setNewsletter,
}: SignUpFormProps) {
  const { t } = useTranslation();

  const setClientDebounced = useRef(
    debounce(async (value: string) => {
      await clientCheck(value);
    }, 600),
  ).current;

  return (
    <React.Fragment key="signup-submit-fragment">
      <TextInput
        id="email"
        type="text"
        name="email"
        placeholder={t('EMAIL_ADDRESS')}
        required
        onChange={(e): void => {
          setEmail(e.target.value);
        }}
        error={
          errorInformation?.position === 'email' && errorInformation.element
        }
      />

      <PasswordInput
        id="password"
        name="password"
        onChange={setPassword}
        placeholder={t('PASSWORD')}
        showValidation={true}
        required
        error={
          errorInformation?.position === 'password' && errorInformation.element
        }
      />
      <Text
        style={{
          color: 'rgb(17, 17, 17)',
          fontSize: '16px',
          marginTop: '-28px',
          marginBottom: '18px',
        }}
      >
        {t('i_have_voucher')}
      </Text>

      <TextInput
        id="example"
        name="example"
        placeholder="voucher code"
        onChange={(e) => setClientDebounced(e.target.value)}
        framed
      />
      <Checkbox
        id="conditions"
        name="conditions"
        checked={conditionsAccepted}
        onChange={(e): void => {
          setConditionsAccepted(e.target.checked);
        }}
      >
        {t('CONSENT')}{' '}
        <a
          target="_blank"
          href={
            i18n.language === 'pl'
              ? '/pdfs/terms-and-conditions.pdf'
              : '/pdfs/terms-and-conditions_eng.pdf'
          }
        >
          {t('TERMS_OF_USE')}
        </a>{' '}
        {t('ACCEPT')}{' '}
        <a
          target="_blank"
          href={
            i18n.language === 'pl'
              ? '/pdfs/privacy-policy.pdf'
              : '/pdfs/privacy-policy.pdf'
          }
        >
          {t('PRIVACY_POLICY')}
        </a>{' '}
        {t('PRIVACY_POLICY_2')}
      </Checkbox>
      <Checkbox
        id="newsletter"
        name="newsletter"
        checked={newsletter === 'true'}
        onChange={(e): void => {
          setNewsletter(e.target.checked ? 'true' : 'false');
        }}
      >
        {t('MARKETING_CONSENT')}
      </Checkbox>

      <Button
        type="submit"
        style={{ cursor: 'pointer', display: 'block', marginTop: '3rem' }}
        disabled={!conditionsAccepted}
      >
        {t('FREE_REGISTER_BUTTON')}
      </Button>
      {errorInformation?.position === 'default' && errorInformation.element}
      {errorInformation?.position === 'default' && errorInformation.element}
      <Button
        type="button"
        variant="text"
        style={{ marginTop: '2rem' }}
        onClick={(): void => setCurrentVariant('signin')}
      >
        {t('ALREADY_HAVE_AN_ACCOUNT')}
      </Button>
    </React.Fragment>
  );
}
