import { useTranslation } from 'react-i18next';

import * as React from 'react';
import { Link as GatsbyLink } from 'gatsby';
import { Box, Button, Grid } from 'theme-ui';
import styled from 'styled-components';
import type { RouteComponentProps } from '@reach/router';

import { useCourses, useInstructors, useUser } from '@youga/youga-client-api';

import useTracking from '../../../hooks/useTracking';

import Container from '../../01_atoms/Container/Container';
import VisualFeedbackInfoBox from '../../01_atoms/VisualFeedbackInfoBox/VisualFeedbackInfoBox';
import Header, { useIsMenuOpen } from '../../02_molecules/Header/Header';
import PoseComparisonCarousel from '../../02_molecules/PoseComparisonCarousel/PoseComparisonCarousel';
import Text from '../../01_atoms/Text/Text';

import YourInstructors from './YourInstructors';
import YourFavouriteCourses from './YourFavouriteCourses';
import YourCourses from './YourCourses';
import YourJourney from './YourJourney';
import Spinner from '../../01_atoms/Spinner/Spinner';
import i18n from '../../../i18n/i18n';

const Link = styled(GatsbyLink)`
  text-decoration: none;
`;

const HomeSection = (_props: RouteComponentProps): React.ReactElement => {
  const { t } = useTranslation();

  const { data: coursesData } = useCourses(i18n.language);
  const { data: instructorsData } = useInstructors();
  const { data: userr } = useUser();
  console.log('user', userr);
  const { track } = useTracking();
  const isMenuOpen = useIsMenuOpen();

  // 1.- Track HomeSection
  React.useEffect(() => {
    track('home-section');
  }, [track]);

  return (
    <>
      <Header showButton="menu" theme="light" showConnectButton />

      <Box
        sx={{ marginTop: '32px', transition: 'filter ease-in 0.3s' }}
        style={isMenuOpen ? { filter: 'blur(5px)' } : {}}
      >
        <Container>
          {coursesData == null || instructorsData == null ? (
            <Box
              sx={{
                position: 'relative',
                height: '100vh',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner />
            </Box>
          ) : null}
          <YourJourney />

          <YourCourses />
          <YourFavouriteCourses />

          <Link to="/app/courses">
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                mt: 4,
                textDecoration: 'none',
              }}
            >
              <Button variant="whiteBorder">{t('ALLE_KURSE')}</Button>
            </Box>
          </Link>

          <Box sx={{ mt: [4, 5], mb: [5, 9] }}>
            <Grid columns={[1, 2]} gap="2.5rem">
              <YourInstructors />

              <Box>
                <Text
                  variant="h2light"
                  sx={{ fontSize: [3, 4], mt: [3, 7], mb: [3, 4] }}
                >
                  {t('SO_FUNKTIONIERT_S')}
                </Text>

                <Box sx={{ mx: ['-20px', 0], position: 'relative' }}>
                  <VisualFeedbackInfoBox />
                </Box>
              </Box>
            </Grid>
          </Box>
        </Container>

        <Box sx={{ pt: [4], pb: [5, 12] }}>
          <Container>
            <Text
              variant="h2light"
              sx={{ fontSize: [3, 4], mt: 0, mb: [3, 4] }}
            >
              {t('DEINE_POSE_COMPARISONS')}
            </Text>
          </Container>

          <PoseComparisonCarousel />
        </Box>
      </Box>
    </>
  );
};

export default HomeSection;
