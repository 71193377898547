import * as React from 'react';
import type { RouteComponentProps } from '@reach/router';
import { Box } from 'theme-ui';

import Header from '../../02_molecules/Header/Header';
import Container from '../../01_atoms/Container/Container';
import Text from '../../01_atoms/Text/Text';
import { t } from 'i18next';
import TrainingTipsSection from './TrainingTipsSection';

import {
  useTrainingSession,
  useTrainingSessions,
} from '@youga/youga-client-api';
import Trophy from './Trophy';
import useTracking from '../../../hooks/useTracking';

export type Props = RouteComponentProps<{
  trainingSessionId: string;
  rawFeedback: string;
}>;
interface Feedback {
  [key: string]: { feedback: number; success: number; undetected: number };
}

function FinishSessionSection({ trainingSessionId, rawFeedback }: Props) {
  const {
    data: trainingSession,
    isValidating,
    updateAnimationImage,
    patchTrainingSession,
  } = useTrainingSession(trainingSessionId);
  const { track } = useTracking();
  const feedback = React.useMemo(
    () => JSON.parse(rawFeedback ?? ''),
    [rawFeedback],
  );

  /**
   * [Mount]
   * Resets the scroll-position to top when rendering this page
   */
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  React.useEffect(() => {
    console.log('asdasd');
    track('post-training-session-completion-update', {
      userGroup: 'PhysioStretch',
      feedback: feedback,
    });
    console.log('asdasdad');
  }, [track, feedback]);

  const isFinished = trainingSession?.finished === true;

  React.useEffect(() => {
    async function markTrainingSessionAsFinished() {
      if (isFinished === true || isValidating) {
        return;
      }

      try {
        await patchTrainingSession({
          finished: true,
        });
      } catch (error: unknown) {
        console.log('got an error while patching trainingsession', error);
      }
    }
    markTrainingSessionAsFinished();
  }, [isValidating, isFinished, patchTrainingSession]);

  const isLoaded = trainingSession != null;
  const animatedImage = trainingSession?.animatedImage;

  /**
   * [after loading training-session]
   * Sets the finished flag to the training-session
   */
  React.useEffect(() => {
    const updateFinishedFlag = async (): Promise<void> => {
      if (!isLoaded || isValidating) {
        return;
      }

      if (animatedImage != null) {
        return;
      }

      try {
        await updateAnimationImage();
      } catch (error: unknown) {
        console.log('got an error while requesting animated image', error);
      }
    };

    updateFinishedFlag();
  }, [isValidating, isLoaded, animatedImage, updateAnimationImage]);

  const { data: videoSessionsData } = useTrainingSessions();

  const getScoreFromFeedback = React.useCallback((sessionFeedback) => {
    const feedbackSuccess = Object.keys(sessionFeedback).reduce(
      (prev, next) => prev + (sessionFeedback as Feedback)[next].success,
      0,
    );
    const feedbackFail = Object.keys(sessionFeedback).reduce(
      (prev, next) => prev + (sessionFeedback as Feedback)[next].feedback,
      0,
    );
    return Math.floor(
      (feedbackSuccess / (feedbackSuccess + feedbackFail) || 0) * 100,
    );
  }, []);

  const score = React.useMemo(() => {
    return getScoreFromFeedback(feedback);
  }, [feedback, getScoreFromFeedback]);

  const highestScoreSoFar = React.useMemo(() => {
    return videoSessionsData
      ?.filter((item) => item.completion && item.completion > 60)
      ?.map((item) => getScoreFromFeedback(item.videoSessionFeedback || {}))
      .reduce((max, current) => {
        return current > max ? current : max;
      }, 0);
  }, [videoSessionsData, getScoreFromFeedback]);

  const [color, greetMessage, icon] = React.useMemo(() => {
    if (score < 20) {
      return ['255, 193, 33', t('finish_session_thanks.level_1_message'), '💪🏻'];
    }
    if (score < 40) {
      return [
        '246, 125, 227',
        t('finish_session_thanks.level_2_message', '✨'),
      ];
    }
    if (score < 60) {
      return [
        '158, 123, 198',
        t('finish_session_thanks.level_3_message'),
        '🚀',
      ];
    }
    if (score < 80) {
      return [
        '132, 184, 254',
        t('finish_session_thanks.level_4_message'),
        '🔥',
      ];
    }
    return ['94, 214, 93', t('finish_session_thanks.level_5_message'), '🏆'];
  }, [score, t]);

  return (
    <>
      <Header showButton="back" theme="light" fullWidth />
      <Container sx={{ position: 'relative' }}>
        <Box>
          <Text
            sx={{
              textAlign: 'center',
              marginTop: '30px',
              color: `rgb(${color})`,
            }}
            variant="h5"
          >
            {t('finish_session_thanks.congratulation')} {icon}
          </Text>
          <Text sx={{ textAlign: 'center', lineHeight: '35px' }}>
            {greetMessage}
          </Text>
          <Text sx={{ textAlign: 'center' }}>
            {t('finish_session_thanks.your_score')}
          </Text>
          <Box
            sx={{
              width: '220px',
              height: '220px',
              backgroundColor: `rgba(${color}, 0.1)`,
              margin: 'auto',
              marginTop: '30px',
              borderRadius: 400,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Box
              sx={{
                width: '150px',
                height: '150px',
                borderRadius: 400,
                backgroundColor: `rgb(${color})`,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Text
                sx={{ fontSize: '83px', color: 'white', fontWeight: '500' }}
              >
                {score}
              </Text>
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              margin: 'auto',
              maxWidth: '250px',
              marginTop: '30px',
            }}
          >
            <Text
              sx={{
                color: '#F48222',
                fontSize: '18px',
                fontWeight: '600',
                textAlign: 'center',
              }}
            >
              {t('finish_session_thanks.best_score')}
            </Text>
            <Box
              sx={{
                width: 55,
                height: 55,
                backgroundColor: '#F48222',
                borderRadius: 400,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  width: 43,
                  height: 43,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  backgroundColor: 'white',
                  borderRadius: 400,
                }}
              >
                <Text
                  sx={{ color: '#F48222', fontSize: '25px', fontWeight: '600' }}
                >
                  {highestScoreSoFar}
                </Text>
              </Box>
            </Box>
          </Box>
        </Box>
        <TrainingTipsSection />
        <Box
          sx={{
            height: '800px',
            position: 'absolute',
            top: '0px',
            width: '100%',
            '@media (max-width: 900px)': { display: 'none' },
          }}
        >
          <Trophy
            color={`rgb(${color})`}
            scale={'0.23'}
            style={{
              position: 'absolute',
              top: '-315px',
              left: '-40px',
              transform: 'rotate(20deg)',
            }}
          />
          <Trophy
            color={`rgb(${color})`}
            style={{
              position: 'absolute',
              top: '-107px',
              left: '-200px',
              transform: 'rotate(20deg)',
            }}
          />
          <Trophy
            color={`rgb(${color})`}
            style={{
              position: 'absolute',
              top: '45px',
              left: '-40px',
              transform: 'rotate(-20deg)',
            }}
          />

          <Trophy
            color={`rgb(${color})`}
            style={{
              position: 'absolute',
              top: '-315px',
              right: '-40px',
              transform: 'rotate(20deg)',
            }}
          />
          <Trophy
            color={`rgb(${color})`}
            style={{
              position: 'absolute',
              top: '-107px',
              right: '-200px',
              transform: 'rotate(-20deg)',
            }}
          />
          <Trophy
            color={`rgb(${color})`}
            scale={'0.23'}
            style={{
              position: 'absolute',
              top: '45px',
              right: '-40px',
              transform: 'rotate(-20deg)',
            }}
          />

          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: `rgba(${color}, 0.1)`,
              position: 'absolute',
              borderRadius: 400,
              top: '60px',
              left: '150px',
            }}
          />
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: `rgba(${color}, 0.1)`,
              position: 'absolute',
              borderRadius: 400,
              top: '260px',
              left: '320px',
            }}
          />
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: `rgba(${color}, 0.1)`,
              position: 'absolute',
              borderRadius: 400,
              top: '440px',
              left: '150px',
            }}
          />

          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: `rgba(${color}, 0.1)`,
              position: 'absolute',
              borderRadius: 400,
              top: '60px',
              right: '150px',
            }}
          />
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: `rgba(${color}, 0.1)`,
              position: 'absolute',
              borderRadius: 400,
              top: '260px',
              right: '320px',
            }}
          />
          <Box
            sx={{
              width: '20px',
              height: '20px',
              backgroundColor: `rgba(${color}, 0.1)`,
              position: 'absolute',
              borderRadius: 400,
              top: '440px',
              right: '150px',
            }}
          />
        </Box>
      </Container>
    </>
  );
}

export default FinishSessionSection;
