import React from 'react';
import { navigate } from 'gatsby';
import '../i18n/i18n';
import Seo from '../global/Seo';

function IndexPage() {
  React.useEffect(() => {
    navigate(`/app${window?.location.search}`);
  }, []);

  return (
    <>
      <Seo
        title="PhysioStretch"
        titleTemplate="Let's stretch together to achieve your goal"
      />
    </>
  );
}

export default IndexPage;
