import React, { SyntheticEvent } from 'react';

import { Image, Box } from 'theme-ui';
import Text from '../../01_atoms/Text/Text';
import BtnCloseWhite from '../../../assets/btnCloseWhite.svg';
import BtnPerspectiveSwitch from '../../../assets/icons/btnPerspectiveSwitch.inline.svg';
import Checkbox from '../../01_atoms/Checkbox/Checkbox';
import { useTranslation } from 'react-i18next';

export interface MirrorSettingsProps {
  visible: boolean;
  onClose: () => void;
  audioEnabled: boolean;
  showMirror: boolean;
  activeMirrorSize: string;
  onAudioEnabledChange: (state: boolean) => void;
  onShowMirrorChange: (state: boolean) => void;
  onMirrorSizeChange: (size: string) => void;
  onFlipImage: () => void;
}

/**
 * This MirrorContainer component renders the OpenTok Element
 *
 * If there is a placementSelector defined, we monitor the boundingRect of this element and place our Mirror element in the exact same position
 */
const MirrorSettings: React.FC<MirrorSettingsProps> = ({
  visible,
  onClose,
  audioEnabled,
  showMirror,
  activeMirrorSize,
  onAudioEnabledChange,
  onShowMirrorChange,
  onMirrorSizeChange,
  onFlipImage,
}: MirrorSettingsProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        transition: 'opacity ease-out 0.3s',
        backgroundColor: 'rgba(0, 0, 0, 0.8)',
        px: 4,
        py: 3,
        overflow: 'auto',
      }}
      style={{
        opacity: visible ? 1 : 0,
        pointerEvents: visible ? 'all' : 'none',
      }}
    >
      <Image
        src={BtnCloseWhite}
        sx={{
          position: 'absolute',
          width: '18px',
          height: '18px',
          top: '1rem',
          right: '1rem',
          cursor: 'pointer',
        }}
        onClick={onClose}
      />

      <Text
        sx={{
          fontFamily: 'heading',
          fontSize: 2,
          fontWeight: '600',
          color: 'white',
          mt: 0,
          mb: 4,
          paddingLeft: '120px',
        }}
      >
        {t('SETTINGS')}
      </Text>

      <Text
        sx={{
          color: 'white',
          fontSize: 0,
        }}
      >
        {t('CHOOSE_SIEZE')}
      </Text>

      <Box
        sx={{
          fontSize: 0,
          mt: 2,
          color: 'white',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {[
          { width: 30, height: 20, label: t('LARGE'), id: 'large' },
          { width: 20, height: 14, label: t('MEDIUM'), id: 'medium' },
          { width: 14, height: 10, label: t('SMALL'), id: 'small' },
        ].map(
          ({ width, height, label, id }, index): React.ReactElement => (
            <Box
              key={id}
              sx={{
                ml: index > 0 ? 4 : 0,
                display: 'inline-flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: activeMirrorSize === id ? undefined : 'pointer',
              }}
              onClick={(): void => {
                if (activeMirrorSize !== id) {
                  onMirrorSizeChange(id);
                }
              }}
            >
              <Box
                sx={{
                  width: `${width}px`,
                  height: `${height}px`,
                  border: '2px solid white',
                  borderRadius: '2px',
                  backgroundColor:
                    activeMirrorSize === id ? 'primary' : 'transparent',
                }}
              />
              <Text
                sx={{
                  ml: 2,
                  color: 'white',
                  fontSize: 0,
                }}
              >
                {label}
              </Text>
            </Box>
          ),
        )}
      </Box>

      <Text
        sx={{
          mt: 4,
          color: 'white',
          fontSize: 0,
        }}
      >
        {t('MIRROR_REPRESENTAION')}
      </Text>

      <Box
        sx={{
          mt: 1,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Box sx={{ width: '30px', lineHeight: 1 }}>
          <BtnPerspectiveSwitch
            sx={{
              width: '2rem',
              cursor: 'pointer',
              path: { fill: 'white' },
            }}
            onClick={onFlipImage}
          />
        </Box>
        <Text
          sx={{
            ml: 2,
            mt: 2,
            color: 'white',
            fontSize: 0,
          }}
        >
          {t('MIRROR_VIEW')}
        </Text>
      </Box>

      <Text
        sx={{
          mt: 4,
          color: 'white',
          fontSize: 0,
        }}
      >
        {t('AUDIO_COUNTDOWN')}
      </Text>

      <Box
        sx={{
          color: 'white',
          fontSize: 0,
          mt: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Checkbox
          id="audio-feedback"
          name="audio-feedback"
          checked={audioEnabled}
          onChange={(e: SyntheticEvent<HTMLInputElement>): void => {
            onAudioEnabledChange((e.target as HTMLInputElement).checked);
          }}
          sx={{ cursor: 'pointer', mb: 0 }}
        >
          <Text
            sx={{
              ml: '6px',
              color: 'white',
              fontSize: 0,
              mt: '2px',
            }}
          >
            {t('AUDIO_COUNTDOWN_ON')}
          </Text>
        </Checkbox>
      </Box>

      <Text
        sx={{
          color: 'white',
          fontSize: 0,
          mt: 4,
        }}
      >
        {t('SMART_MIRROR_HIDE')}
      </Text>

      <Box
        sx={{
          fontSize: 0,
          mt: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        <Checkbox
          id="show-mirror"
          name="show-mirror"
          checked={showMirror}
          onChange={(e: SyntheticEvent<HTMLInputElement>): void => {
            onShowMirrorChange((e.target as HTMLInputElement).checked);
          }}
          sx={{ cursor: 'pointer' }}
        >
          <Text
            sx={{
              ml: '6px',
              mt: '2px',
              color: 'white',
              fontSize: 0,
            }}
          >
            {t('SMART_MIRROR_ON')}
          </Text>
        </Checkbox>
      </Box>
    </Box>
  );
};

export default MirrorSettings;
