import React from 'react';
import { Box } from 'theme-ui';
import PoseComparisonSessionItem from '../../01_atoms/PoseComparisonSessionItem/PoseComparisonSessionItem';

interface PoseComparisonSessionCarouselItemProps {
  poseImage: string;
  userImage: string;
  sessionName: string;
  sessionId: string;
  date?: string;
  target: string;
  isLastElement: boolean;
}
const PoseComparisonSessionCarouselItem: React.FC<
  PoseComparisonSessionCarouselItemProps
> = ({
  poseImage,
  userImage,
  sessionName,
  sessionId,
  date,
  target,
  isLastElement,
}: PoseComparisonSessionCarouselItemProps) => (
  <Box
    sx={{
      scrollSnapStop: 'always',
      scrollSnapAlign: 'start',
      pl: '20px',
      pr: isLastElement ? '20px' : '57px',
      ml: 0,
      mt: 2,
      mb: 5, // render shadow. OverflowX: scroll results in OverflowY: auto and not visible
      minWidth: isLastElement ? '272px' : '309px',
      width: isLastElement ? '272px' : '309px',
    }}
  >
    <PoseComparisonSessionItem
      poseImage={poseImage}
      userImage={userImage}
      sessionName={sessionName}
      sessionId={sessionId}
      date={date}
      target={target}
    />
  </Box>
);

export default PoseComparisonSessionCarouselItem;
