import { useTranslation } from 'react-i18next';

import * as React from 'react';
import styled from 'styled-components';

import { Link, navigate } from 'gatsby';
import { useUser } from '@youga/youga-client-api';
import ProgressBar from '@ramonak/react-progress-bar';

import { CourseSession } from '../../../types/interfaces';
import { getGoalMeta } from '../../../constants/enums';
import BadgeScreen from '../../03_organisms/BadgeScreen/BadgeScreen';
import JourneyCourseItem from './JourneyCourseItem';
import Text from '../../01_atoms/Text/Text';

import './JourneyStyle.css';

const StyledLink = styled(Link)`
  display: block;
  text-decoration: none;

  &:hover h6 {
    text-decoration: underline;
  }
`;

interface Props {
  bgImage: string;
  teaserText?: string;
  progressText?: string;
  onPressMyProgress: () => void | Promise<void>;
  videos?: CourseSession[];
  allSessionsFinished?: boolean;
  finishedSessions?: number;
  courseId: string;
}

function JourneySection(props: React.PropsWithChildren<Props>) {
  const { t } = useTranslation();

  const {
    bgImage,
    teaserText,
    videos,
    courseId,
    allSessionsFinished,
    finishedSessions,
  } = props;

  const finishedCount = finishedSessions || 0;
  const videosCount = videos?.length || 0;

  const { data: user, updatePreferences } = useUser();

  const hideJourney = async (): Promise<void> => {
    try {
      await updatePreferences({
        ...user?.preferences,
        hideJourney: true,
      });
    } catch (e) {}
  };

  return (
    <div>
      <div>
        <Text
          variant="h2light"
          style={{ fontWeight: '600', margin: 0, marginTop: '30px' }}
        >
          {t('HALLO')}

          {user?.preferences?.name}
        </Text>
      </div>
      <div
        className={'journeyImageWrap'}
        style={{
          backgroundImage: `url("${bgImage}")`,
        }}
      >
        <div className={'progressBarWrap'}>
          <ProgressBar
            labelColor="black"
            labelSize="10px"
            baseBgColor="#ffffff"
            bgColor="#a4d9d6"
            barContainerClassName="progressBarContainer"
            className="progressBar"
            labelClassName="progressLabel"
            completed={(100 / videosCount) * finishedCount}
            labelAlignment="center"
            customLabel={finishedCount + '/' + videosCount}
          />
        </div>
      </div>

      {teaserText ? (
        <Text
          variant="h2light"
          style={{
            display: 'inline-block',
            width: '332px',
            height: '78px',
            fontSize: '32px',
            marginTop: '20px',
            marginBottom: '15px',
            fontWeight: '600',
            marginLeft: '20px',
            letterSpacing: '0px',
          }}
        >
          {allSessionsFinished && (
            <div className={'hideJourneyBtn'} onClick={() => hideJourney()} />
          )}
          {teaserText}
        </Text>
      ) : null}

      {allSessionsFinished ? (
        <div className={'journeyVideoWrap'}>
          <div
            style={{
              position: 'relative',
              textAlign: 'center',
              alignItems: 'center',
              paddingTop: '60px',
              marginLeft: '150px',
              width: '200px',
              float: 'left',
            }}
          >
            <BadgeScreen courseId={courseId} onPress={() => {}} />
          </div>
        </div>
      ) : (
        <div className={'journeyVideoWrap'}>
          {!!videos &&
            videos.map((item: CourseSession) => {
              const isIntroVideo = item.id === '0';
              const goalMeta =
                item.courseId != null ? getGoalMeta(item.courseId) : null;
              return (
                <div className={'journeyCourseItemWrapper'} key={item.id}>
                  {isIntroVideo ? (
                    <StyledLink
                      to={`/app/course-detail/${item?.courseId}/start-intro`}
                    >
                      <JourneyCourseItem
                        topline={t('INTRO')}
                        subline=""
                        course={item.videoSession}
                        introUrl={item.introUrl}
                        introThumb={item.introThumb}
                        finished={item.finished}
                      />
                    </StyledLink>
                  ) : (
                    item.videoSession != null &&
                    item.courseId != null && (
                      <StyledLink to={`/app/session/${item.videoSession?.id}`}>
                        <JourneyCourseItem
                          topline={`${Number(item.id) + 1}${
                            goalMeta
                              ? ` - ${t(
                                  `GOAL_OPTIONS_NAMES.${goalMeta.id}` as any,
                                )}`
                              : ''
                          }`}
                          subline={t('POSE_INFO')}
                          course={item.videoSession}
                          finished={item.finished}
                        />
                      </StyledLink>
                    )
                  )}
                </div>
              );
            })}
        </div>
      )}
    </div>
  );
}

export default JourneySection;
